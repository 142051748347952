










import { defineComponent } from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'

export default defineComponent({
  name: 'page',
  setup() {
    const { template } = useGetters(['template'])

    const pages = {
      /* eslint-disable quote-props */
      about: () => import('@/views/pages/About.vue'),
      contact: () => import('@/views/pages/Contact.vue'),
      faq: () => import('@/views/pages/Faq.vue'),
      'form-other': () => import('@/views/pages/ContactOther.vue'),
      'form-project': () => import('@/views/pages/ContactOldProject.vue'),
      'home-organizing-landing': () =>
        import('@/views/pages/HomeOrganizing.vue'),
      'home-organizer': () => import('@/views/pages/HomeOrganizers.vue'),
      jobs: () => import('@/views/pages/Jobs.vue'),
      'magazine-landing': () => import('@/views/pages/MagazineLanding.vue'),
      'partner-landing': () => import('@/views/pages/PartnerLanding.vue'),
      personality: () => import('@/views/pages/Personality.vue'),
      'personality-landing': () =>
        import('@/views/pages/PersonalityLanding.vue'),
      process: () => import('@/views/pages/Process.vue'),
      prices: () => import('@/views/pages/Prices.vue'),
      page: () => import('@/views/pages/Subpage.vue'),
      'social-projects': () => import('@/views/pages/Sustainability.vue'),
      sustainability: () => import('@/views/pages/Sustainability.vue'),
      /* eslint-enable quote-props */
    }

    return {
      template,
      pages,
    }
  },
})
